<template>
  <div class="camear-wrap" ref="cameraBox">
    <HikVision ref="hik" :width="width" :height="height" :pointCode="cameraCode" :layout="'1x2'" v-if="show" :info="cameraInfo" />
  </div>
</template>

<script>
import HikVision from '../../components/HikVision.vue'
export default {
  components: {
    HikVision
  },
  data () {
    return {
      width: 0,
      height: 0,
      cameraInfo: {
        appKey: '28022728',
        appSecret: 'wACQUoEN8q08bu1DzHL1',
        host: '61.180.39.9:1443'
      },
      cameraCode: 'cf34fb128cec480a88e27735f785ea9b,dbda2d0385334c569840821d440269aa',
      show: false
    }
  },
  mounted () {
    this.width = this.$refs.cameraBox.offsetWidth
    this.height = this.$refs.cameraBox.offsetHeight
    this.show = true
  },
  methods: {
    // 控制方向
    // changeStatus (opt) {
    //   switch (opt) {
    //     case 'up':
    //       this.changeDirection(0)
    //       break
    //     case 'down':
    //       this.changeDirection(1)
    //       break
    //     case 'left':
    //       this.changeDirection(2)
    //       break
    //     case 'right':
    //       this.changeDirection(3)
    //       break
    //     case 'stop':
    //       this.stop()
    //       break
    //     case 'zoomIn':
    //       this.changeDirection(8)
    //       break
    //     case 'zoomOut':
    //       this.changeDirection(9)
    //       break
    //   }
    // },
    // changeDirection (direction) {
    //   const param = new FormData()
    //   param.append('accessToken', this.$refs.ysy.token)
    //   param.append('direction', direction)
    //   param.append('deviceSerial', 'F06352655')
    //   param.append('channelNo', '1')
    //   param.append('speed', 1)
    //   this.$axios.post('https://open.ys7.com/api/lapp/device/ptz/start', param).then((res) => { setTimeout(() => { this.stop() }, 500) })
    // },
    // stop () {
    //   const param = new FormData()
    //   param.append('accessToken', this.$refs.ysy.token)
    //   param.append('deviceSerial', 'F06352655')
    //   param.append('channelNo', '1')
    //   param.append('speed', 0)
    //   this.$axios.post('https://open.ys7.com/api/lapp/device/ptz/stop', param)
    // }
  }
}
</script>

<style lang="scss" scoped>
.camear-wrap {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
</style>
